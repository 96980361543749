<template>
  <div class="program-page">
    <!-- START ADS -->
    <DetailProgramTopAds />
    <!-- END ADS -->
    <section id="program">
      <div class="container">
        <ImageResponsive
          :imageUrl="
            home.items.channel && home.items.channel.logo
              ? home.items.channel.logo
              : 'https://narasi.tv/storage/images/dummy-banner.png'
          "
          :imageUrlMobile="
            home.items.channel && home.items.channel.logo
              ? home.items.channel.logo
              : 'https://narasi.tv/storage/images/dummy.png'
          "
          :fromUrl="true"
          width="400"
          height="400"
        />
        <div class="copy-wrapper">
          <h2 class="channel-title">
            {{
              home.items.channel && home.items.channel.title
                ? home.items.channel.title
                : ""
            }}
          </h2>
          <p
            class="desc"
            v-html="
              home.items.channel && home.items.channel.description
                ? home.items.channel.description
                : ''
            "
          ></p>
        </div>
      </div>
    </section>
    <section class="program-content">
      <div class="program-nav container">
        <Link
          :to="{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: $route.params.childBrand,
              slugProgram: $route.params.slugProgram,
            },
            query: { ...removeObject('page'), ...{ type: 'highlights' } },
          }"
          class="box"
          :class="$route.query.type === 'highlights' ? 'Link-exact-active' : ''"
          >HIGHLIGHTS
        </Link>
        <Link
          :to="{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: $route.params.childBrand,
              slugProgram: $route.params.slugProgram,
            },
            query:
              videos.pagination && videos.pagination.pages > 1
                ? {
                    ...$route.query,
                    ...{
                      type: 'video',
                      page: page,
                    },
                  }
                : { ...$route.query, ...{ type: 'video' } },
          }"
          class="box"
          :class="$route.query.type === 'video' ? 'Link-exact-active' : ''"
          >VIDEO
        </Link>
        <Link
          :to="{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: $route.params.childBrand,
              slugProgram: $route.params.slugProgram,
            },
            query: { ...removeObject('page'), ...{ type: 'playlist' } },
          }"
          class="box"
          :class="$route.query.type === 'playlist' ? 'Link-exact-active' : ''"
          >PLAYLIST
        </Link>
      </div>
      <div class="home" v-show="$route.query.type === 'highlights'">
        <div class="boosted-content">
          <div class="container">
            <Link
              v-if="home.items.article && home.items.article.channel"
              :to="
                isArticleText
                  ? {
                      name: `read-channel-slug`,
                      params: {
                        channel: home.items.article.channel.slug,
                        slug: home.items.article.slug,
                      },
                      query: {
                        ref: 'program-' + home.items.article.channel.slug,
                      },
                    }
                  : {
                      name: 'video-channel-slug',
                      params: {
                        channel: home.items.article.channel.slug,
                        slug: home.items.article.slug,
                      },
                      query: {
                        ref: 'program-' + home.items.article.channel.slug,
                      },
                    }
              "
              class="boosted-thumbnail"
            >
              <ImageResponsive
                :imageUrl="
                  home.items.article && home.items.article.thumbnail.large
                    ? home.items.article.thumbnail.large
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :fromUrl="true"
              />
              <div
                class="duration-wrapper"
                :class="{
                  backgroundAds:
                    home.items.article && home.items.article.isAdvertorial,
                }"
              >
                <p
                  class="advertorial"
                  v-if="
                    home.items.article
                      ? home.items.article.isAdvertorial
                      : false
                  "
                >
                  Ad
                </p>
                <div
                  class="article-reguler"
                  v-else-if="
                    home.items.article ? home.items.article.isText : false
                  "
                >
                  <!-- <Icons name="book" /> -->
                  <i class="bi bi-book-half"></i>
                  <p class="text">TEKS</p>
                </div>
                <p v-else class="duration">
                  {{ home.items.article ? home.items.article.timeVideo : "" }}
                </p>
              </div>
            </Link>
            <div class="boosted-detail">
              <div class="channel-category">
                <Link
                  v-if="home.items.article && home.items.article.channel"
                  :to="{
                    name: 'program-childBrand-slugProgram',
                    params: {
                      childBrand: $route.params.childBrand,
                      slugProgram: $route.params.slugProgram,
                    },
                    query: {
                      type: 'highlights',
                    },
                  }"
                  class="channel"
                  >{{ home.items.article.channel.title }}</Link
                >
                <!-- <p class="channel">
                  {{
                    home.items.article && home.items.article.channel
                      ? home.items.article.channel.title
                      : ""
                  }}
                </p> -->
                <span>&bull;</span>
                <Link
                  v-if="home.items.article && home.items.article.category"
                  :to="{
                    name: 'interest',
                    query: {
                      type: home.items.article.category.slug,
                    },
                  }"
                  class="category"
                  >{{ home.items.article.category.title }}</Link
                >
                <!-- <p class="category">
                  {{
                    home.items.article && home.items.article.category
                      ? home.items.article.category.title
                      : ""
                  }}
                </p> -->
              </div>
              <Link
                v-if="home.items.article && home.items.article.channel"
                :to="
                  isArticleText
                    ? {
                        name: `read-channel-slug`,
                        params: {
                          channel: home.items.article.channel.slug,
                          slug: home.items.article.slug,
                        },
                        query: {
                          ref: 'program-' + home.items.article.channel.slug,
                        },
                      }
                    : {
                        name: 'video-channel-slug',
                        params: {
                          channel: home.items.article.channel.slug,
                          slug: home.items.article.slug,
                        },
                        query: {
                          ref: 'program-' + home.items.article.channel.slug,
                        },
                      }
                "
                class="title"
              >
                {{
                  home.items.article && home.items.article.title
                    ? home.items.article.title
                    : ""
                }}
              </Link>
              <div class="desc-wrapper">
                <p
                  v-if="home.items.article"
                  class="desc"
                  v-html="
                    home.items.article.content
                      .replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/gim, ' ')
                      .substr(0, 400)
                  "
                ></p>
                <Link
                  v-if="home.items.article && home.items.article.channel"
                  :to="
                    isArticleText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: home.items.article.channel.slug,
                            slug: home.items.article.slug,
                          },
                          query: {
                            ref: 'program-' + home.items.article.channel.slug,
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: home.items.article.channel.slug,
                            slug: home.items.article.slug,
                          },
                          query: {
                            ref: 'program-' + home.items.article.channel.slug,
                          },
                        }
                  "
                  >...read more</Link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="child-program">
          <div
            v-for="(item, index) in home.items.playlists"
            :key="index + item.id"
            class="container"
          >
            <div class="title-wrapper">
              <TitleSection :title="item.title" />
              <Link
                v-if="item.items && item.items.length > 4"
                :to="{
                  name: 'playlist-category-channel',
                  params: {
                    category: item.slug,
                    channel: item.channel.slug,
                  },
                }"
                class="lihat-semua"
                >Lihat Semua
              </Link>
            </div>
            <div class="list-card-child-program" v-if="item.items.length > 0">
              <div
                v-swiper="setSwiperOptions(index)"
                :instance-name="'mySwiperNumber' + index"
                @ready="setArrow()"
                @transition-start="onSwiperSlideChange(index)"
              >
                <div class="swiper-wrapper">
                  <ShimmerCardHome
                    v-show="home.loading"
                    v-for="(shimmer, index) in 5"
                    :key="index"
                    class="swiper-slide"
                  />
                  <CardTwo
                    v-show="!home.loading"
                    v-for="subItem in item.items"
                    :key="subItem.article.id"
                    :image="
                      subItem.article.thumbnail &&
                      subItem.article.thumbnail.medium
                        ? subItem.article.thumbnail.medium
                        : 'https://narasi.tv/storage/images/dummy.png'
                    "
                    :imageFromUrl="true"
                    :duration="
                      subItem.article.timeVideo
                        ? subItem.article.timeVideo
                        : '00:00'
                    "
                    :channel="subItem.article.channel.title"
                    :category="
                      subItem.article.category
                        ? subItem.article.category.title
                        : 'category'
                    "
                    :categorySlug="
                      subItem.article.category
                        ? subItem.article.category.slug
                        : 'category'
                    "
                    :title="subItem.article.title"
                    :isText="subItem.article.isText"
                    :isAdvertorial="subItem.article.isAdvertorial"
                    :to="
                      subItem.article.isText
                        ? {
                            name: `read-channel-slug`,
                            params: {
                              channel: subItem.article.channel.slug,
                              slug: subItem.article.slug,
                            },
                            query: {
                              ref: 'program-' + subItem.article.channel.slug,
                            },
                          }
                        : {
                            name: 'video-channel-slug',
                            params: {
                              channel: subItem.article.channel.slug,
                              slug: subItem.article.slug,
                            },
                            query: {
                              ref: 'program-' + subItem.article.channel.slug,
                            },
                          }
                    "
                    class="swiper-slide"
                  />
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <div
                class="shadow-arrow shadow-left"
                :class="[
                  returnArrow(index, 'left') ? 'hide' : '',
                  { hide: item.items.length < 5 },
                ]"
              ></div>
              <div
                class="shadow-arrow shadow-right"
                :class="[
                  returnArrow(index, 'right') ? 'hide' : '',
                  { hide: item.items.length < 5 },
                ]"
              ></div>
              <Icons
                name="arrow-left"
                class="arrow prev"
                :class="[
                  returnArrow(index, 'left') ? 'hide' : '',
                  'prev-number-' + index,
                  { hide: item.items.length < 5 },
                ]"
              />
              <Icons
                name="arrow-right"
                class="arrow next"
                :class="[
                  returnArrow(index, 'right') ? 'hide' : '',
                  'next-number-' + index,
                  { hide: item.items.length < 5 },
                ]"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="video" v-show="$route.query.type === 'video'">
        <div class="container">
          <div class="list-video">
            <ShimmerCardHome
              v-show="videos.loading"
              v-for="(item, index) in 8"
              :key="index"
              class="swiper-slide shimmer-desktop"
            />
            <ShimmerCardHomeLandscape
              v-show="videos.loading"
              v-for="(item, index) in 5"
              :key="'x' + index"
              class="swiper-slide shimmer-mobile"
            />
            <CardTen
              v-show="!videos.loading"
              v-for="item in videos.items.videos"
              :key="item.id"
              :image="
                item.thumbnail && item.thumbnail.medium
                  ? item.thumbnail.medium
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :imageFromUrl="true"
              :duration="item.timeVideo ? item.timeVideo : '00:00'"
              :channel="item.channel.title"
              :channelSlug="$route.params.slugProgram"
              :programSlug="$route.params.childBrand"
              :category="item.category ? item.category.title : ''"
              :categorySlug="item.category ? item.category.slug : ''"
              :title="item.title"
              :isText="item.isText"
              :isAdvertorial="item.isAdvertorial"
              :to="
                item.isText
                  ? {
                      name: `read-channel-slug`,
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'program-' + item.channel.slug,
                      },
                    }
                  : {
                      name: 'video-channel-slug',
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'program-' + item.channel.slug,
                      },
                    }
              "
              class="card-video"
            />
          </div>
          <div
            class="pagination-cards"
            v-if="videos.pagination && videos.pagination.pages > 1"
          >
            <paginate
              v-if="
                videos.pagination &&
                videos.pagination.pages &&
                videos.pagination.pages > 1
              "
              :page-count="videos.pagination.pages"
              :page-range="3"
              :margin-pages="1"
              prev-class="arrow-pagination"
              next-class="arrow-pagination"
              :click-handler="clickCallback"
              :container-class="'pagination'"
              :page-class="'page-item'"
              v-model="page"
            >
              <template slot="prevContent">
                <Icons name="arrow-left" color="#757575" />
              </template>
              <template slot="nextContent">
                <Icons name="arrow-right" color="#757575" />
              </template>
            </paginate>
          </div>
        </div>
      </div>
      <div class="playlist" v-show="$route.query.type === 'playlist'">
        <div class="container">
          <div
            class="list-playlist"
            v-if="
              playlists.items.playlists && playlists.items.playlists.length > 0
            "
          >
            <ShimmerCardHome
              v-show="playlists.loading"
              v-for="(item, index) in 8"
              :key="index"
              class="shimmer-desktop"
            />
            <ShimmerCardHomeLandscape
              v-show="playlists.loading"
              v-for="(item, index) in 5"
              :key="'x' + index"
              class="shimmer-mobile"
            />
            <CardThree
              v-show="!playlists.loading"
              v-for="(item, index) in playlists.items.playlists"
              :key="item.id + index"
              :image="
                item.thumbnail && item.thumbnail.large
                  ? item.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :imageFromUrl="true"
              :totalVideo="item.itemCount"
              :title="item.title"
              :to="{
                name: 'playlist-category-channel',
                params: {
                  category: item.slug,
                  channel: item.channel.slug,
                },
                query: {
                  ref: 'program-' + item.channel.slug,
                },
              }"
              :toDetail="{
                name: 'playlist-category-channel-slug',
                params: {
                  category: item.slug,
                  channel:
                    item.channel && item.channel.slug ? item.channel.slug : '',
                  slug: item.firstItem,
                },
                query: {
                  ref: 'program-' + item.channel.slug,
                },
              }"
              class="card-playlist desktop"
            />
            <CardSix
              v-show="!playlists.loading"
              v-for="item in playlists.items.playlists"
              :key="item.id"
              :image="
                item.thumbnail && item.thumbnail.large
                  ? item.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :imageFromUrl="true"
              :totalVideo="item.itemCount"
              :title="item.title"
              :to="{
                name: 'playlist-category-channel',
                params: {
                  category: item.slug,
                  channel: item.channel.slug,
                },
              }"
              class="card-playlist mobile"
            />
          </div>
          <div class="no-playlist" v-else>
            <Icons name="error-yellow" />
            <h2 class="title">Playlist Belum Tersedia.</h2>
            <p class="desc">
              Maaf, saat ini playlist yang anda cari belum ada. <br />Silahkan
              cek konten Narasi lainnya.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import CardThree from "/components/cards/CardThree.vue";
import CardTwo from "/components/cards/CardTwo.vue";
import CardSix from "/components/cards/CardSix.vue";
import CardTen from "/components/cards/CardTen.vue";
import DetailProgramTopAds from "/components/ads/program/DetailProgramTopAds.vue";
import Vue from "vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import TitleSection from "@/components/TitleSection";
import ImageResponsive from "@/components/ImageResponsive";
import ShimmerCardHome from "@/components/shimmer/CardHome";
import ShimmerCardHomeLandscape from "@/components/shimmer/CardHomeLandscape";

Vue.component("paginate", Paginate);
export default {
  async asyncData({ app, params, store, route, error }) {
    try {
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}channels/${params.slugProgram}/home`
      );
      var home_ = {
        channel: response.data.channel,
        article: response.data.article,
        playlists: response.data.playlists,
      };
      store.commit("program/SET_HOME", home_);
      store.commit("seo/SET_SEO", {
        title: "Program " + response.data.channel.title,
        desc: response.data.channel.description,
        image:
          response.data.channel && response.data.channel.thumbnail
            ? response.data.channel.thumbnail.large
            : "https://narasi.tv/storage/images/dummy.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
      return {};
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    ShimmerCardHomeLandscape,
    ShimmerCardHome,
    TitleSection,
    ImageResponsive,
    Link,
    Icons,
    CardTwo,
    CardThree,
    CardSix,
    CardTen,
    DetailProgramTopAds,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("program/SET_HOME", []);
    next();
  },
  data() {
    return {
      arrows: [],
      page: 1,
      limit: 395,
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      home: (state) => {
        return state.program.home;
      },
      videos: (state) => {
        return state.program.videos;
      },
      playlists: (state) => {
        return state.program.playlists;
      },
    }),
    isArticleText() {
      return this.home.items.article ? this.home.items.article.isText : false;
    },
  },
  watch: {
    "$route.query.page": {
      handler: function (page) {
        if (page) {
          let page_ = parseInt(page);
          this.page = page_;
          this.$store.dispatch("program/getVideos", {
            slug: this.$route.params.slugProgram,
            page: this.page,
          });
        } else {
          this.page = 1;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async initData() {
      // await this.$store.dispatch(
      //   "program/getHome",
      //   this.$route.params.slugProgram
      // );
      await this.$store.dispatch("program/getVideos", {
        slug: this.$route.params.slugProgram,
        page: this.page,
      });
      await this.$store.dispatch(
        "program/getPlaylists",
        this.$route.params.slugProgram
      );
    },
    clickCallback(page) {
      this.page = page;

      this.$router.replace({
        name: "program-childBrand-slugProgram",
        params: {
          childBrand: this.$route.params.childBrand,
          slugProgram: this.$route.params.slugProgram,
        },
        query: {
          ...this.$route.query,
          ...{ type: this.$route.query.type, page: page },
        },
      });

      this.$store.dispatch("program/getVideos", {
        slug: this.$route.params.slugProgram,
        page: page,
      });
    },
    setSwiperOptions(i) {
      for (const x in this.home.items.playlists) {
        if (i == x) {
          var prevEl_ = ".prev-number-" + i;
          var nextEl_ = ".next-number-" + i;
        }
      }
      let options = {
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        observer: true,
        observeParents: true,
        navigation: {
          prevEl: prevEl_,
          nextEl: nextEl_,
        },
        breakpoints: {
          1023: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 8,
          },
        },
      };
      return options;
    },
    onSwiperSlideChange(i) {
      let string = "mySwiperNumber" + i;
      for (const x in Object.keys(this)) {
        if (Object.keys(this)[x] == string) {
          this.arrows[i].prev = this[Object.keys(this)[x]].isBeginning;
          this.arrows[i].next = this[Object.keys(this)[x]].isEnd;
        }
      }
    },
    setArrow() {
      for (const x in this.home.items.playlists) {
        this.arrows.push({
          prev: true,
          next: false,
        });
      }
    },
    returnArrow(i, param) {
      for (const x in this.arrows) {
        if (param === "left" && i == x) {
          return this.arrows[i].prev;
        }
        if (param === "right" && i == x) {
          return this.arrows[i].next;
        }
      }
    },
    removeObject(param) {
      let obj = this.$route.query;
      delete obj[param];
      return obj;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.program-page {
  #program {
    padding: 32px 0;
    .container {
      display: flex;
      align-items: center;
      gap: 32px;
      @media only screen and (max-width: 1024px) {
        gap: 24px;
      }
      .image {
        width: 100px;
        height: auto;
        border-radius: 50%;
        @media only screen and (max-width: 1024px) {
          width: 50px;
          height: 50px;
        }
        ::v-deep img {
          object-fit: cover;
        }
      }
      .copy-wrapper {
        width: auto;
        @media only screen and (max-width: 1024px) {
          width: fit-content;
        }
        .channel-title {
          font-size: 32px;
          font-weight: 500;
          color: 252525;
          margin-bottom: 16px;
          @media only screen and (max-width: 1024px) {
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 8px;
          }
        }

        .desc {
          font-size: 18px;
          font-weight: 400;
          color: #616161;
          margin-bottom: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .program-content {
    .program-nav {
      display: flex;
      height: 51px;
      position: relative;
      @media only screen and (max-width: 1024px) {
        height: 46px;
      }
      &::before {
        content: "";
        position: absolute;
        background-color: #e0e0e0;
        width: 100%;
        height: 2px;
        bottom: 0;
        z-index: 0;
      }
      .box {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 16px;
        font-weight: 700;
        color: #9e9e9e;
        border-bottom: 4px solid transparent;
        padding: 0 20px;
        margin: 0 20px;
        z-index: 1;
        position: relative;
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
          border-width: 3px;
        }
        &:first-child {
          padding-left: 0;
          margin-left: 0;
        }

        &:hover {
          color: #8166c8 !important;
          border-color: #8166c8;
        }

        &:active {
          color: #6242b4 !important;
          border-color: #8166c8;
        }

        &.Link-exact-active {
          color: #4a25aa !important;
          border-color: #4a25aa;

          &:hover {
            color: #4a25aa !important;
          }
        }
      }
    }

    .home {
      margin: 32px 0 48px;

      .boosted-content {
        .container {
          display: flex;
          @media only screen and (max-width: 1024px) {
            padding: 0;
            flex-direction: column;
          }

          .boosted-thumbnail {
            position: relative;
            width: 40%;
            height: fit-content;
            @media only screen and (max-width: 1024px) {
              width: 100%;
            }

            .image {
              width: 100%;
              height: auto;
              border-radius: 10px;
              overflow: hidden;
              @media only screen and (max-width: 1024px) {
                border-radius: 0;
              }
            }

            .duration-wrapper {
              background: rgba(0, 0, 0, 0.7);
              padding: 3px 6px;
              position: absolute;
              bottom: 0;
              right: 0;
              border-top-left-radius: 10px;
              border-bottom-right-radius: 10px;
              @media only screen and (max-width: 1024px) {
                border-top-left-radius: 6px;
                border-bottom-right-radius: 0;
              }
              &.backgroundAds {
                background: rgba(128, 128, 128, 0.7);
                padding: 3px 8px;
              }
              .advertorial {
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 10px;
                }
              }
              .article-reguler {
                display: flex;
                align-items: center;
                .bi-book-half {
                  width: 16px;
                  height: auto;
                  margin-right: 2px;
                  color: #ffffff;
                }
                .text {
                  font-size: 10px;
                  font-weight: 500;
                  color: #ffffff;
                  margin: 0;
                }
              }
              .duration {
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 10px;
                }
              }
            }
          }

          .boosted-detail {
            width: 60%;
            padding-left: 24px;
            @media only screen and (max-width: 1024px) {
              width: 100%;
              padding: 8px 20px 0;
            }

            .channel-category {
              display: flex;
              align-items: center;
              margin-bottom: 8px;

              .channel,
              .category {
                font-size: 14px;
                font-weight: 400;
                color: #4a25aa;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 12px;
                }
              }

              span {
                font-size: 12px;
                padding: 0 10px;
              }
            }

            .title {
              font-size: 32px;
              font-weight: 500;
              color: #0c0b0d;
              line-height: 40px;
              margin-bottom: 16px;
              display: block;
              @media only screen and (max-width: 1024px) {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 8px;
              }
            }

            .desc-wrapper {
              .desc {
                font-size: 14px;
                font-weight: 400;
                color: #616161;
                line-height: 30px;
                margin: 0;
                display: inline;
                @media only screen and (max-width: 1024px) {
                  font-size: 12px;
                  line-height: 25px;
                }

                ::v-deep p {
                  &:last-child {
                    display: inline;
                  }
                }
              }

              span {
                font-size: 14px;
                font-weight: 700;
                cursor: pointer;
                @media only screen and (max-width: 1024px) {
                  font-size: 12px;
                }
                span {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .child-program {
        .container {
          margin-top: 48px;
          @media only screen and (max-width: 1024px) {
            margin-top: 32px;
          }

          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .lihat-semua {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline !important;

              &:hover {
                color: #4a25aa !important;
              }

              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }

          .list-card-child-program {
            position: relative;

            .shadow-arrow {
              pointer-events: none;
              position: absolute;
              top: 0;
              width: 100px;
              height: 100%;
              padding: 0;
              background: rgb(0, 0, 0);
              z-index: 2;
              opacity: 1;
              transition: ease-in-out 0.5s;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.shadow-left {
                left: 0;
                background: linear-gradient(
                  to left,
                  hsla(0, 0%, 100%, 0),
                  rgba(241, 241, 241, 1)
                );
              }

              &.shadow-right {
                right: 0;
                background: linear-gradient(
                  to right,
                  hsla(0, 0%, 100%, 0),
                  rgba(241, 241, 241, 1)
                );
              }

              &.hide {
                opacity: 0;
                z-index: 0;
              }
            }

            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }

            .swiper-container {
              @media only screen and (max-width: 1024px) {
                padding: 20px !important;
                margin: -20px !important;
              }

              .swiper-wrapper {
                height: fit-content !important;
              }
            }
          }
        }
      }
    }

    .video {
      margin: 32px 0 48px;

      .container {
        .list-video {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          @media only screen and (max-width: 1024px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-row-gap: 0;
          }

          .shimmer-desktop {
            display: flex;
            @media only screen and (max-width: 1024px) {
              display: none;
            }
          }

          .shimmer-mobile {
            display: none;
            @media only screen and (max-width: 1024px) {
              display: flex;
            }
          }

          .card-video {
            width: 100%;
            @media only screen and (max-width: 1024px) {
              padding: 16px 0;
              border-bottom: 1px solid lightgray;
            }

            &:first-child {
              @media only screen and (max-width: 1024px) {
                padding-top: 0;
              }
            }

            &:last-child {
              @media only screen and (max-width: 1024px) {
                padding-bottom: 0;
                border: none;
              }
            }
          }
        }

        .pagination-cards {
          padding: 24px 0;
          @media only screen and (max-width: 1024px) {
            padding: 32px 0 0;
          }

          .pagination {
            justify-content: flex-end;
            margin: 0;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              justify-content: center;
            }

            ::v-deep .arrow {
              display: flex;
              align-items: center;
            }

            ::v-deep .arrow-pagination {
              svg {
                width: 25px;
                height: 25px;
              }
            }

            ::v-deep .page-item {
              a {
                font-size: 12px;
                font-weight: 400;
                color: #757575;
                background: #e0e0e0;
                width: 25px;
                height: 25px;
                margin: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;
              }

              &.active {
                a {
                  background: #4a25aa;
                  color: #fafafa !important;
                }
              }

              &:hover {
                a {
                  background: #4a25aa;
                  color: #9e9e9e !important;
                }
              }
            }
          }
        }
      }
    }

    .playlist {
      margin: 32px 0 48px;

      .container {
        .list-playlist {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          @media only screen and (max-width: 1024px) {
            display: flex;
            flex-direction: column;
            grid-row-gap: 0;
          }

          .shimmer-desktop {
            display: flex;
            @media only screen and (max-width: 1024px) {
              display: none;
            }
          }

          .shimmer-mobile {
            display: none;
            @media only screen and (max-width: 1024px) {
              display: flex;
            }
          }

          .card-playlist {
            width: 100%;
            @media only screen and (max-width: 1024px) {
              border-bottom: 1px solid lightgray;
            }

            &:last-child {
              @media only screen and (max-width: 1024px) {
                border: none;
              }
            }

            &.desktop {
              display: flex;
              @media only screen and (max-width: 1024px) {
                display: none;
              }
            }

            &.mobile {
              display: none;
              @media only screen and (max-width: 1024px) {
                display: flex;
              }
            }
          }
        }

        .no-playlist {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          svg {
            width: 137px;
            height: auto;
            margin-bottom: 24px;
            @media only screen and (max-width: 1024px) {
              width: 80px;
            }
          }

          .title {
            font-size: 24px;
            font-weight: 500;
            color: #0c0b0d;
            text-align: center;
            @media only screen and (max-width: 1024px) {
              font-size: 18px;
            }
          }

          .desc {
            font-size: 20px;
            color: #333333;
            text-align: center;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
