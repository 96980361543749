<template>
<div class="template-container">
  <LivePolling :detail="{
    slug: $route.params.slug,
    polling: 1
  }"></LivePolling>
</div>
</template>

<script>
import LivePolling from "@/components/live/Polling";
export default {
  components: { LivePolling },
  layout: "blank"
}
</script>
