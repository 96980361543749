var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"program-page"},[_c('DetailProgramTopAds'),_vm._v(" "),_c('section',{attrs:{"id":"program"}},[_c('div',{staticClass:"container"},[_c('ImageResponsive',{attrs:{"imageUrl":_vm.home.items.channel && _vm.home.items.channel.logo
            ? _vm.home.items.channel.logo
            : 'https://narasi.tv/storage/images/dummy-banner.png',"imageUrlMobile":_vm.home.items.channel && _vm.home.items.channel.logo
            ? _vm.home.items.channel.logo
            : 'https://narasi.tv/storage/images/dummy.png',"fromUrl":true,"width":"400","height":"400"}}),_vm._v(" "),_c('div',{staticClass:"copy-wrapper"},[_c('h2',{staticClass:"channel-title"},[_vm._v("\n          "+_vm._s(_vm.home.items.channel && _vm.home.items.channel.title
              ? _vm.home.items.channel.title
              : "")+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(
            _vm.home.items.channel && _vm.home.items.channel.description
              ? _vm.home.items.channel.description
              : ''
          )}})])],1)]),_vm._v(" "),_c('section',{staticClass:"program-content"},[_c('div',{staticClass:"program-nav container"},[_c('Link',{staticClass:"box",class:_vm.$route.query.type === 'highlights' ? 'Link-exact-active' : '',attrs:{"to":{
          name: 'program-childBrand-slugProgram',
          params: {
            childBrand: _vm.$route.params.childBrand,
            slugProgram: _vm.$route.params.slugProgram,
          },
          query: { ..._vm.removeObject('page'), ...{ type: 'highlights' } },
        }}},[_vm._v("HIGHLIGHTS\n      ")]),_vm._v(" "),_c('Link',{staticClass:"box",class:_vm.$route.query.type === 'video' ? 'Link-exact-active' : '',attrs:{"to":{
          name: 'program-childBrand-slugProgram',
          params: {
            childBrand: _vm.$route.params.childBrand,
            slugProgram: _vm.$route.params.slugProgram,
          },
          query:
            _vm.videos.pagination && _vm.videos.pagination.pages > 1
              ? {
                  ..._vm.$route.query,
                  ...{
                    type: 'video',
                    page: _vm.page,
                  },
                }
              : { ..._vm.$route.query, ...{ type: 'video' } },
        }}},[_vm._v("VIDEO\n      ")]),_vm._v(" "),_c('Link',{staticClass:"box",class:_vm.$route.query.type === 'playlist' ? 'Link-exact-active' : '',attrs:{"to":{
          name: 'program-childBrand-slugProgram',
          params: {
            childBrand: _vm.$route.params.childBrand,
            slugProgram: _vm.$route.params.slugProgram,
          },
          query: { ..._vm.removeObject('page'), ...{ type: 'playlist' } },
        }}},[_vm._v("PLAYLIST\n      ")])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.type === 'highlights'),expression:"$route.query.type === 'highlights'"}],staticClass:"home"},[_c('div',{staticClass:"boosted-content"},[_c('div',{staticClass:"container"},[(_vm.home.items.article && _vm.home.items.article.channel)?_c('Link',{staticClass:"boosted-thumbnail",attrs:{"to":_vm.isArticleText
                ? {
                    name: `read-channel-slug`,
                    params: {
                      channel: _vm.home.items.article.channel.slug,
                      slug: _vm.home.items.article.slug,
                    },
                    query: {
                      ref: 'program-' + _vm.home.items.article.channel.slug,
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: _vm.home.items.article.channel.slug,
                      slug: _vm.home.items.article.slug,
                    },
                    query: {
                      ref: 'program-' + _vm.home.items.article.channel.slug,
                    },
                  }}},[_c('ImageResponsive',{attrs:{"imageUrl":_vm.home.items.article && _vm.home.items.article.thumbnail.large
                  ? _vm.home.items.article.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png',"fromUrl":true}}),_vm._v(" "),_c('div',{staticClass:"duration-wrapper",class:{
                backgroundAds:
                  _vm.home.items.article && _vm.home.items.article.isAdvertorial,
              }},[(
                  _vm.home.items.article
                    ? _vm.home.items.article.isAdvertorial
                    : false
                )?_c('p',{staticClass:"advertorial"},[_vm._v("\n                Ad\n              ")]):(
                  _vm.home.items.article ? _vm.home.items.article.isText : false
                )?_c('div',{staticClass:"article-reguler"},[_c('i',{staticClass:"bi bi-book-half"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("TEKS")])]):_c('p',{staticClass:"duration"},[_vm._v("\n                "+_vm._s(_vm.home.items.article ? _vm.home.items.article.timeVideo : "")+"\n              ")])])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"boosted-detail"},[_c('div',{staticClass:"channel-category"},[(_vm.home.items.article && _vm.home.items.article.channel)?_c('Link',{staticClass:"channel",attrs:{"to":{
                  name: 'program-childBrand-slugProgram',
                  params: {
                    childBrand: _vm.$route.params.childBrand,
                    slugProgram: _vm.$route.params.slugProgram,
                  },
                  query: {
                    type: 'highlights',
                  },
                }}},[_vm._v(_vm._s(_vm.home.items.article.channel.title))]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("•")]),_vm._v(" "),(_vm.home.items.article && _vm.home.items.article.category)?_c('Link',{staticClass:"category",attrs:{"to":{
                  name: 'interest',
                  query: {
                    type: _vm.home.items.article.category.slug,
                  },
                }}},[_vm._v(_vm._s(_vm.home.items.article.category.title))]):_vm._e()],1),_vm._v(" "),(_vm.home.items.article && _vm.home.items.article.channel)?_c('Link',{staticClass:"title",attrs:{"to":_vm.isArticleText
                  ? {
                      name: `read-channel-slug`,
                      params: {
                        channel: _vm.home.items.article.channel.slug,
                        slug: _vm.home.items.article.slug,
                      },
                      query: {
                        ref: 'program-' + _vm.home.items.article.channel.slug,
                      },
                    }
                  : {
                      name: 'video-channel-slug',
                      params: {
                        channel: _vm.home.items.article.channel.slug,
                        slug: _vm.home.items.article.slug,
                      },
                      query: {
                        ref: 'program-' + _vm.home.items.article.channel.slug,
                      },
                    }}},[_vm._v("\n              "+_vm._s(_vm.home.items.article && _vm.home.items.article.title
                  ? _vm.home.items.article.title
                  : "")+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"desc-wrapper"},[(_vm.home.items.article)?_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(
                  _vm.home.items.article.content
                    .replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/gim, ' ')
                    .substr(0, 400)
                )}}):_vm._e(),_vm._v(" "),(_vm.home.items.article && _vm.home.items.article.channel)?_c('Link',{attrs:{"to":_vm.isArticleText
                    ? {
                        name: `read-channel-slug`,
                        params: {
                          channel: _vm.home.items.article.channel.slug,
                          slug: _vm.home.items.article.slug,
                        },
                        query: {
                          ref: 'program-' + _vm.home.items.article.channel.slug,
                        },
                      }
                    : {
                        name: 'video-channel-slug',
                        params: {
                          channel: _vm.home.items.article.channel.slug,
                          slug: _vm.home.items.article.slug,
                        },
                        query: {
                          ref: 'program-' + _vm.home.items.article.channel.slug,
                        },
                      }}},[_vm._v("...read more")]):_vm._e()],1)],1)],1)]),_vm._v(" "),_c('div',{staticClass:"child-program"},_vm._l((_vm.home.items.playlists),function(item,index){return _c('div',{key:index + item.id,staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('TitleSection',{attrs:{"title":item.title}}),_vm._v(" "),(item.items && item.items.length > 4)?_c('Link',{staticClass:"lihat-semua",attrs:{"to":{
                name: 'playlist-category-channel',
                params: {
                  category: item.slug,
                  channel: item.channel.slug,
                },
              }}},[_vm._v("Lihat Semua\n            ")]):_vm._e()],1),_vm._v(" "),(item.items.length > 0)?_c('div',{staticClass:"list-card-child-program"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper",value:(_vm.setSwiperOptions(index)),expression:"setSwiperOptions(index)"}],attrs:{"instance-name":'mySwiperNumber' + index},on:{"ready":function($event){return _vm.setArrow()},"transition-start":function($event){return _vm.onSwiperSlideChange(index)}}},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((5),function(shimmer,index){return _c('ShimmerCardHome',{directives:[{name:"show",rawName:"v-show",value:(_vm.home.loading),expression:"home.loading"}],key:index,staticClass:"swiper-slide"})}),_vm._v(" "),_vm._l((item.items),function(subItem){return _c('CardTwo',{directives:[{name:"show",rawName:"v-show",value:(!_vm.home.loading),expression:"!home.loading"}],key:subItem.article.id,staticClass:"swiper-slide",attrs:{"image":subItem.article.thumbnail &&
                    subItem.article.thumbnail.medium
                      ? subItem.article.thumbnail.medium
                      : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":subItem.article.timeVideo
                      ? subItem.article.timeVideo
                      : '00:00',"channel":subItem.article.channel.title,"category":subItem.article.category
                      ? subItem.article.category.title
                      : 'category',"categorySlug":subItem.article.category
                      ? subItem.article.category.slug
                      : 'category',"title":subItem.article.title,"isText":subItem.article.isText,"isAdvertorial":subItem.article.isAdvertorial,"to":subItem.article.isText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: subItem.article.channel.slug,
                            slug: subItem.article.slug,
                          },
                          query: {
                            ref: 'program-' + subItem.article.channel.slug,
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: subItem.article.channel.slug,
                            slug: subItem.article.slug,
                          },
                          query: {
                            ref: 'program-' + subItem.article.channel.slug,
                          },
                        }}})})],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})]),_vm._v(" "),_c('div',{staticClass:"shadow-arrow shadow-left",class:[
                _vm.returnArrow(index, 'left') ? 'hide' : '',
                { hide: item.items.length < 5 },
              ]}),_vm._v(" "),_c('div',{staticClass:"shadow-arrow shadow-right",class:[
                _vm.returnArrow(index, 'right') ? 'hide' : '',
                { hide: item.items.length < 5 },
              ]}),_vm._v(" "),_c('Icons',{staticClass:"arrow prev",class:[
                _vm.returnArrow(index, 'left') ? 'hide' : '',
                'prev-number-' + index,
                { hide: item.items.length < 5 },
              ],attrs:{"name":"arrow-left"}}),_vm._v(" "),_c('Icons',{staticClass:"arrow next",class:[
                _vm.returnArrow(index, 'right') ? 'hide' : '',
                'next-number-' + index,
                { hide: item.items.length < 5 },
              ],attrs:{"name":"arrow-right"}})],1):_vm._e()])}),0)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.type === 'video'),expression:"$route.query.type === 'video'"}],staticClass:"video"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"list-video"},[_vm._l((8),function(item,index){return _c('ShimmerCardHome',{directives:[{name:"show",rawName:"v-show",value:(_vm.videos.loading),expression:"videos.loading"}],key:index,staticClass:"swiper-slide shimmer-desktop"})}),_vm._v(" "),_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{directives:[{name:"show",rawName:"v-show",value:(_vm.videos.loading),expression:"videos.loading"}],key:'x' + index,staticClass:"swiper-slide shimmer-mobile"})}),_vm._v(" "),_vm._l((_vm.videos.items.videos),function(item){return _c('CardTen',{directives:[{name:"show",rawName:"v-show",value:(!_vm.videos.loading),expression:"!videos.loading"}],key:item.id,staticClass:"card-video",attrs:{"image":item.thumbnail && item.thumbnail.medium
                ? item.thumbnail.medium
                : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '00:00',"channel":item.channel.title,"channelSlug":_vm.$route.params.slugProgram,"programSlug":_vm.$route.params.childBrand,"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial,"to":item.isText
                ? {
                    name: `read-channel-slug`,
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'program-' + item.channel.slug,
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'program-' + item.channel.slug,
                    },
                  }}})})],2),_vm._v(" "),(_vm.videos.pagination && _vm.videos.pagination.pages > 1)?_c('div',{staticClass:"pagination-cards"},[(
              _vm.videos.pagination &&
              _vm.videos.pagination.pages &&
              _vm.videos.pagination.pages > 1
            )?_c('paginate',{attrs:{"page-count":_vm.videos.pagination.pages,"page-range":3,"margin-pages":1,"prev-class":"arrow-pagination","next-class":"arrow-pagination","click-handler":_vm.clickCallback,"container-class":'pagination',"page-class":'page-item'},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('template',{slot:"prevContent"},[_c('Icons',{attrs:{"name":"arrow-left","color":"#757575"}})],1),_vm._v(" "),_c('template',{slot:"nextContent"},[_c('Icons',{attrs:{"name":"arrow-right","color":"#757575"}})],1)],2):_vm._e()],1):_vm._e()])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.type === 'playlist'),expression:"$route.query.type === 'playlist'"}],staticClass:"playlist"},[_c('div',{staticClass:"container"},[(
            _vm.playlists.items.playlists && _vm.playlists.items.playlists.length > 0
          )?_c('div',{staticClass:"list-playlist"},[_vm._l((8),function(item,index){return _c('ShimmerCardHome',{directives:[{name:"show",rawName:"v-show",value:(_vm.playlists.loading),expression:"playlists.loading"}],key:index,staticClass:"shimmer-desktop"})}),_vm._v(" "),_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{directives:[{name:"show",rawName:"v-show",value:(_vm.playlists.loading),expression:"playlists.loading"}],key:'x' + index,staticClass:"shimmer-mobile"})}),_vm._v(" "),_vm._l((_vm.playlists.items.playlists),function(item,index){return _c('CardThree',{directives:[{name:"show",rawName:"v-show",value:(!_vm.playlists.loading),expression:"!playlists.loading"}],key:item.id + index,staticClass:"card-playlist desktop",attrs:{"image":item.thumbnail && item.thumbnail.large
                ? item.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"totalVideo":item.itemCount,"title":item.title,"to":{
              name: 'playlist-category-channel',
              params: {
                category: item.slug,
                channel: item.channel.slug,
              },
              query: {
                ref: 'program-' + item.channel.slug,
              },
            },"toDetail":{
              name: 'playlist-category-channel-slug',
              params: {
                category: item.slug,
                channel:
                  item.channel && item.channel.slug ? item.channel.slug : '',
                slug: item.firstItem,
              },
              query: {
                ref: 'program-' + item.channel.slug,
              },
            }}})}),_vm._v(" "),_vm._l((_vm.playlists.items.playlists),function(item){return _c('CardSix',{directives:[{name:"show",rawName:"v-show",value:(!_vm.playlists.loading),expression:"!playlists.loading"}],key:item.id,staticClass:"card-playlist mobile",attrs:{"image":item.thumbnail && item.thumbnail.large
                ? item.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"totalVideo":item.itemCount,"title":item.title,"to":{
              name: 'playlist-category-channel',
              params: {
                category: item.slug,
                channel: item.channel.slug,
              },
            }}})})],2):_c('div',{staticClass:"no-playlist"},[_c('Icons',{attrs:{"name":"error-yellow"}}),_vm._v(" "),_c('h2',{staticClass:"title"},[_vm._v("Playlist Belum Tersedia.")]),_vm._v(" "),_vm._m(0)],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"desc"},[_vm._v("\n            Maaf, saat ini playlist yang anda cari belum ada. "),_c('br'),_vm._v("Silahkan\n            cek konten Narasi lainnya.\n          ")])
}]

export { render, staticRenderFns }