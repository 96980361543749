var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"program-page"},[_c('ProgramTopAds'),_vm._v(" "),_c('section',{staticClass:"program-category",attrs:{"id":"categories"}},[_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperCategories",value:(_vm.swiperOptions.categories),expression:"swiperOptions.categories",arg:"mySwiperCategories"}],staticClass:"py-2"},[(_vm.programs.loading)?_c('ShimmerCategory'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"swiper-wrapper"},[_c('Link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.programs.loading),expression:"!programs.loading"}],staticClass:"swiper-slide category btn-active",attrs:{"to":{
              name: 'program',
            }}},[_vm._v("Semua\n          ")]),_vm._v(" "),_vm._l((_vm.programs.items),function(category,index){return _c('Link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.programs.loading),expression:"!programs.loading"}],key:index,staticClass:"swiper-slide category",attrs:{"to":{
              name: 'program-childBrand',
              params: {
                childBrand: category.slug,
              },
            }}},[_vm._v(_vm._s(category.title)+"\n          ")])})],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})],1)])]),_vm._v(" "),_vm._l((4),function(item,index){return _c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.allProgram.loading),expression:"allProgram.loading"}],key:index + 'x',staticClass:"program mb-4",attrs:{"id":"program"}},[_c('div',{staticClass:"container"},[_c('Shimmer',{staticClass:"mb-3",attrs:{"width":"200px","height":"20px"}}),_vm._v(" "),_c('div',{staticClass:"list-card-program d-flex"},[_c('ShimmerCardProgram')],1)],1)])}),_vm._v(" "),_vm._l((_vm.allProgram.items),function(item,index){return _c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.allProgram.loading),expression:"!allProgram.loading"}],key:item.id,staticClass:"program",attrs:{"id":"program"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('ProgramTitle',{staticClass:"title",attrs:{"title":item.title,"image":item.logo,"to":{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: item.program.slug,
              slugProgram: item.slug,
            },
            query: {
              type: 'highlights',
            },
          }}}),_vm._v(" "),(item.articles && item.articles.length > 4 && item.program)?_c('Link',{staticClass:"lihat-semua",attrs:{"to":{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: item.program.slug,
              slugProgram: item.slug,
            },
            query: {
              type: 'highlights',
            },
          }}},[_vm._v("Selengkapnya\n        ")]):_vm._e()],1),_vm._v(" "),(item.articles && item.articles.length > 0)?_c('div',{staticClass:"list-card-program"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper",value:(_vm.setSwiperOptions(index)),expression:"setSwiperOptions(index)"}],attrs:{"instance-name":'mySwiperNumber' + index}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((item.articles),function(subItem){return _c('CardTwo',{key:subItem.id,staticClass:"swiper-slide",attrs:{"to":subItem.isText
                  ? `/read/${subItem.channel.slug}/${subItem.slug}?ref=${
                      'program-' + subItem.channel.slug
                    }`
                  : `/video/${subItem.channel.slug}/${subItem.slug}?ref=${
                      'program-' + subItem.channel.slug
                    }`,"image":subItem.thumbnail && subItem.thumbnail.large
                  ? subItem.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"title":subItem.title,"channel":subItem.channel && subItem.channel.title
                  ? subItem.channel.title
                  : '',"channelSlug":subItem.channel.slug,"programSlug":item.program.slug,"category":subItem.category ? subItem.category.title : '',"categorySlug":subItem.category ? subItem.category.slug : '',"description":subItem.description,"duration":subItem.timeVideo,"isText":subItem.isText,"isAdvertorial":subItem.isAdvertorial}})}),1),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})]),_vm._v(" "),_c('div',{staticClass:"shadow-arrow shadow-left",class:[
            'prev-number-' + index,
            { hide: item.articles.length < 5 },
          ]},[_c('Icons',{staticClass:"arrow prev",attrs:{"name":"arrow-left"}})],1),_vm._v(" "),_c('div',{staticClass:"shadow-arrow shadow-right",class:[
            'next-number-' + index,
            { hide: item.articles.length < 5 },
          ]},[_c('Icons',{staticClass:"arrow next",attrs:{"name":"arrow-right"}})],1)]):_vm._e()]),_vm._v(" "),(index == 1)?_c('ProgramHorizontalAds1'):_vm._e(),_vm._v(" "),(index == 3)?_c('ProgramHorizontalAds2'):_vm._e()],1)}),_vm._v(" "),_c('div',{staticClass:"button-show-more",class:{ 'mt-5': _vm.allProgram.loadingShowMore }},[(_vm.page < _vm.allProgram.pages && !_vm.allProgram.loadingShowMore)?_c('span',{on:{"click":_vm.showMore}},[_vm._v("LIHAT LEBIH BANYAK")]):_vm._e(),_vm._v(" "),(_vm.allProgram.loadingShowMore)?_c('div',{staticClass:"dot-spin m-auto"}):_vm._e()]),_vm._v(" "),_c('ProgramHorizontalAds3')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }