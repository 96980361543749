<template>
  <div class="program-page">
    <section id="categories" class="program-category">
      <div class="container">
        <div
          class="py-2"
          v-swiper:mySwiperCategories="swiperOptions.categories"
        >
          <ShimmerCategory v-if="programs.loading" />
          <div class="swiper-wrapper">
            <Link
              v-show="!programs.loading"
              class="swiper-slide category"
              :to="{
                name: 'program',
              }"
              >Semua
            </Link>
            <Link
              v-show="!programs.loading"
              v-for="(category, index) in programs.items"
              :key="index"
              :to="{
                name: 'program-childBrand',
                params: {
                  childBrand: category.slug,
                },
              }"
              class="swiper-slide category"
              :class="{
                'btn-active': category.slug == $route.params.childBrand,
              }"
              >{{ category.title }}
            </Link>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
    <section
      id="program"
      class="program mb-4"
      v-show="allProgram.loading"
      v-for="(item, index) in 4"
      :key="index + 'x'"
    >
      <div class="container">
        <Shimmer width="200px" height="20px" class="mb-3" />
        <div class="list-card-program d-flex">
          <ShimmerCardProgram />
        </div>
      </div>
    </section>
    <section
      id="program"
      class="program"
      v-show="!allProgram.loading"
      v-for="(item, index) in allProgram.items"
      :key="item.id"
    >
      <div class="container">
        <div class="title-wrapper">
          <ProgramTitle
            :title="item.title"
            :image="item.logo"
            :to="{
              name: 'program-childBrand-slugProgram',
              params: {
                childBrand: item.program.slug,
                slugProgram: item.slug,
              },
              query: {
                type: 'highlights',
              },
            }"
            class="title"
          />
          <Link
            v-if="item.articles && item.articles.length > 4 && item.program"
            :to="{
              name: 'program-childBrand-slugProgram',
              params: {
                childBrand: item.program.slug,
                slugProgram: item.slug,
              },
              query: {
                type: 'highlights',
              },
            }"
            class="lihat-semua"
            >Selengkapnya
          </Link>
        </div>
        <div
          class="list-card-program"
          v-if="item.articles && item.articles.length > 0"
        >
          <div
            v-swiper="setSwiperOptions(index)"
            :instance-name="'mySwiperNumber' + index"
          >
            <div class="swiper-wrapper">
              <CardTwo
                v-for="subItem in item.articles"
                :key="subItem.id"
                :to="
                  subItem.isText
                    ? {
                        name: 'read-channel-slug',
                        params: {
                          channel: subItem.channel.slug,
                          slug: subItem.slug,
                        },
                        query: {
                          ref: 'program-' + subItem.channel.slug,
                        },
                      }
                    : {
                        name: 'video-channel-slug',
                        params: {
                          channel: subItem.channel.slug,
                          slug: subItem.slug,
                        },
                        query: {
                          ref: 'program-' + subItem.channel.slug,
                        },
                      }
                "
                :image="
                  subItem.thumbnail && subItem.thumbnail.large
                    ? subItem.thumbnail.large
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :imageFromUrl="true"
                :title="subItem.title"
                :channel="
                  subItem.channel && subItem.channel.title
                    ? subItem.channel.title
                    : ''
                "
                :channelSlug="subItem.channel.slug"
                :programSlug="item.program.slug"
                :category="subItem.category ? subItem.category.title : ''"
                :categorySlug="subItem.category ? subItem.category.slug : ''"
                :description="subItem.description"
                :duration="subItem.timeVideo"
                :isText="subItem.isText"
                :isAdvertorial="subItem.isAdvertorial"
                class="swiper-slide"
              />
            </div>
            <div class="swiper-pagination"></div>
          </div>

          <div
            class="shadow-arrow shadow-left"
            :class="[
              'prev-number-' + index,
              { hide: item.articles.length < 5 },
            ]"
          >
            <Icons name="arrow-left" class="arrow prev" />
          </div>
          <div
            class="shadow-arrow shadow-right"
            :class="[
              'next-number-' + index,
              { hide: item.articles.length < 5 },
            ]"
          >
            <Icons name="arrow-right" class="arrow next" />
          </div>
        </div>
      </div>
    </section>
    <div
      class="button-show-more"
      :class="{ 'mt-5': allProgram.loadingShowMore }"
    >
      <span
        v-if="page < allProgram.pages && !allProgram.loadingShowMore"
        @click="showMore"
        >LIHAT LEBIH BANYAK</span
      >
      <div v-if="allProgram.loadingShowMore" class="dot-spin m-auto"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import CardTwo from "/components/cards/CardTwo.vue";
import ShimmerCategory from "@/components/shimmer/Category";
import ShimmerCardProgram from "@/components/shimmer/CardProgram";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import Shimmer from "@/components/Shimmer";
import ProgramTitle from "@/components/ProgramTitle";

export default {
  async asyncData({ app, error, params, store, route }) {
    try {
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}programs/${params.childBrand}?limit=1&page=1`
      );

      store.commit("seo/SET_SEO", {
        title:
          response.data.length > 0 ? `${response.data[0].program.title}` : "",
        desc:
          response.data.length > 0
            ? `Kumpulan artikel dan video brand child ${response.data[0].program.title}`
            : "Kumpulan branch child di Narasi TV.",
        image:
          response.data.length > 0
            ? `${response.data[0].program.logo}`
            : "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    CardTwo,
    Icons,
    ProgramTitle,
    ShimmerCardProgram,
    Shimmer,
    Link,
    ShimmerCategory,
  },
  data() {
    return {
      page: 1,
      swiperOptions: {
        categories: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          mousewheel: {
            releaseOnEdges: true,
          },
          breakpoints: {
            1023: {
              slidesPerView: "auto",
              spaceBetween: 10,
              enabled: true,
            },
            320: {
              slidesPerView: "auto",
              spaceBetween: 10,
            },
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      programs: (state) => {
        return state.program.programs;
      },
      allProgram: (state) => {
        return state.program.allProgram;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("program/getPrograms");
      await this.$store.dispatch("program/getAllPrograms", {
        page: this.page,
        firstLoad: true,
        slug: this.$route.params.childBrand,
      });
    },
    showMore() {
      this.page = this.page + 1;
      this.$store.dispatch("program/getAllPrograms", {
        page: this.page,
        slug: this.$route.params.childBrand,
      });
    },
    setSwiperOptions(i) {
      for (const x in this.allProgram.items) {
        if (i == x) {
          var prevEl_ = ".prev-number-" + i;
          var nextEl_ = ".next-number-" + i;
        }
      }
      let options = {
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        observer: true,
        observeParents: true,
        navigation: {
          prevEl: prevEl_,
          nextEl: nextEl_,
        },
        breakpoints: {
          1023: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 8,
          },
        },
      };
      return options;
    },
  },
  async mounted() {
    await this.initData();
  },
};
</script>

<style lang="scss" scoped>
.program-page {
  #categories {
    padding-top: 32px;
    @media only screen and (max-width: 1024px) {
      padding-top: 24px;
    }
    .swiper-container {
      padding: 10px 0;
      @media only screen and (max-width: 1024px) {
        overflow: visible;
      }
      .swiper-wrapper {
        height: fit-content !important;
        @media only screen and (max-width: 1024px) {
          padding: 20px !important;
          margin: -20px !important;
        }
        .swiper-slide {
          &.category {
            color: #616161;
            background: #fafafa;
            width: fit-content !important;
            border-radius: 50px;
            white-space: nowrap;
            font-size: 16px;
            padding: 10px 30px;
            border: 1px solid #e0e0e0;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
              padding: 5px 15px;
            }

            &:hover {
              @media only screen and (min-width: 1024px) {
                background: #8166c8;
                color: #fafafa !important;
              }
            }

            &:active {
              @media only screen and (min-width: 1024px) {
                background: #6242b4;
                color: #e0e0e0 !important;
              }
            }

            &.btn-active {
              background: #6242b4;
              color: #e0e0e0 !important;
            }
          }
        }
      }
    }
  }
  #program {
    margin-bottom: 48px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 32px;
    }

    &:nth-child(2),
    &:nth-child(6) {
      margin-top: 32px;
    }

    &:last-child {
      @media only screen and (max-width: 1024px) {
        margin-bottom: 48px;
      }
    }

    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0 20px;
      }

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 16px;
        }

        .title {
          text-transform: uppercase;
        }

        .lihat-semua {
          font-size: 16px;
          font-weight: 500;
          color: #4a25aa;
          text-decoration: underline !important;

          &:hover {
            color: #4a25aa !important;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }

      .list-card-program {
        position: relative;

        .shadow-arrow {
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100px;
          height: 100%;
          padding: 0;
          background: rgb(0, 0, 0);
          z-index: 2;
          opacity: 1;
          transition: ease-in-out 0.5s;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          svg {
            pointer-events: visible;
          }

          &.shadow-left {
            left: 0;
            background: linear-gradient(
              to left,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1)
            );
          }

          &.shadow-right {
            right: 0;
            background: linear-gradient(
              to right,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1)
            );
          }

          &.swiper-button-disabled {
            opacity: 0;
            z-index: 0;
          }
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          width: auto;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.prev {
            left: 0;
          }

          &.next {
            right: 0;
          }

          &.hide {
            display: none;
          }
        }

        .swiper-container {
          @media only screen and (max-width: 1024px) {
            padding: 20px !important;
            margin: -20px !important;
          }

          .swiper-wrapper {
            height: 100% !important;
          }
        }
      }
    }
  }
  .button-show-more {
    text-align: center;
    margin-bottom: 32px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 0;
    }
    span {
      cursor: pointer;
      height: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #4a25aa;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      color: #4a25aa;
      padding: 0 20px;
      @media only screen and (max-width: 1024px) {
        height: 40px;
        &:hover {
          color: #4a25aa !important;
        }
      }
      @media only screen and (min-width: 1024px) {
        &:hover {
          color: #8166c8 !important;
          border-color: #8166c8 !important;
        }
      }
    }
  }
}
</style>
